import { useRouter } from 'next/router';
import { ReactNode, useMemo, useState } from 'react';

import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { SideBar } from '../../components/SideBar';
import CodeBlockSettingsContext, {
  CodeBlockLanguage,
} from '../../components/dynamicFlow/markdoc/CodeBlockSettings';
import { Navigation, NavigationItemOrGroup } from '../../types';

import { DFDocHead } from './DFDocHead';
import styles from './DynamicFlowApp.module.css';
import { legacySpecMenu, guidesMenu, featuresMenu, examplesMenu } from './manifest';
import { specMenuItems } from './spec-manifest';
import { Alert } from '@transferwise/components';
import { specMenuItems as specMenuItemsv2 } from './spec-manifestv2';
import { examplesMenu as examplesMenuV2, featuresMenu as featuresMenuV2 } from './manifest-v2';

const IGNORED_ITEMS = ['Legacy Spec', 'v2 Spec', 'v2 Features', 'v2 Examples'];

const DynamicFlowApp = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const path = router.pathname;

  const sidebarMenus: Record<string, NavigationItemOrGroup[]> = {
    Features: featuresMenu,
    Guides: guidesMenu,
    Spec: specMenuItems,
    Examples: examplesMenu,
    'Legacy Spec': legacySpecMenu,
    'v2 Spec': specMenuItemsv2,
    'v2 Features': featuresMenuV2,
    'v2 Examples': examplesMenuV2,
  };

  const navigationItems = [
    {
      title: 'Home',
      link: '/dynamic-flow',
      active: path?.endsWith('/public/dynamic-flow'),
    },
    {
      title: 'Features',
      link: '/dynamic-flow/features',
      active: path?.startsWith('/public/dynamic-flow/features'),
    },
    {
      title: 'Examples',
      link: '/dynamic-flow/examples',
      active: path?.startsWith('/public/dynamic-flow/examples'),
    },
    {
      title: 'Guides',
      link: '/dynamic-flow/guides',
      active: path?.startsWith('/public/dynamic-flow/guides'),
    },
    {
      title: 'Spec',
      link: '/dynamic-flow/docs',
      active: path?.startsWith('/public/dynamic-flow/docs'),
    },
    {
      title: 'Legacy Spec',
      link: '/dynamic-flow/legacy-docs',
      active: path?.startsWith('/public/dynamic-flow/legacy-docs'),
    },
    {
      title: 'v2 Spec',
      link: '/dynamic-flow/v2/docs',
      active: path?.startsWith('/public/dynamic-flow/v2/docs'),
    },
    {
      title: 'v2 Features',
      link: '/dynamic-flow/v2/features',
      active: path?.startsWith('/public/dynamic-flow/v2/features'),
    },
    {
      title: 'v2 Examples',
      link: '/dynamic-flow/v2/examples',
      active: path?.startsWith('/public/dynamic-flow/v2/examples'),
    },
    {
      title: 'Sandbox',
      link: '/dynamic-flow/sandbox',
      active: path?.startsWith('/public/dynamic-flow/sandbox'),
    },
  ];

  const activeItem = navigationItems.find((item) => item.active);

  const sidebarMenu = activeItem?.title ? sidebarMenus[activeItem.title] : undefined;

  const navigation: Navigation = {
    homeLink: '/dynamic-flow',
    title: 'Dynamic Flow',
    items: navigationItems.filter((item) => !IGNORED_ITEMS.some((t) => t === item.title)),
  };

  return (
    <>
      <DFDocHead />
      <div className="site-body dynamic-flow">
        <Header navigation={navigation} />
        <div className="site-container site-container--small">
          <ContentWrapper
            menu={sidebarMenu}
            className={`${styles.sidebarContainer} site-main`}
            path={path}
          >
            <RedirectBanner path={path} />
            {children}
          </ContentWrapper>
        </div>
        <Footer navigation={navigation} />
      </div>
    </>
  );
};

const ContentWrapper = ({
  children,
  menu,
  className = '',
}: {
  children: ReactNode;
  path?: string;
  menu?: NavigationItemOrGroup[];
  className?: string;
}) => {
  const [language, setLanguage] = useState<CodeBlockLanguage>('kotlin');
  const [showFullExample, setShowFullExample] = useState<boolean>(false);

  const featureLanguage = useMemo(
    () => ({ language, setLanguage, showFullExample, setShowFullExample }),
    [language, showFullExample],
  );

  if (!menu) {
    return (
      <main className="site-main">
        <CodeBlockSettingsContext.Provider value={featureLanguage}>
          {children}
        </CodeBlockSettingsContext.Provider>
      </main>
    );
  }
  return (
    <div className={className}>
      <SideBar items={menu} />
      <main>
        <CodeBlockSettingsContext.Provider value={featureLanguage}>
          {children}
        </CodeBlockSettingsContext.Provider>
      </main>
    </div>
  );
};

const RedirectBanner = ({ path }: { path?: string }) => {
  if (!path) {
    return <> </>;
  }

  const showRedirect = ['/docs', '/features', '/examples'];

  if (showRedirect.some((p) => path.includes(p))) {
    if (path.includes('/v2/')) {
      return (
        <Alert
          message="These docs refer to the 2.x release branch."
          action={{
            href: `/dynamic-flow/${path.split('/public/dynamic-flow/v2/')[1]}`,
            text: 'Take me to the 1.x docs',
          }}
          type="warning"
        />
      );
    }
    return (
      <Alert
        message="These docs refer to the 1.x release branch."
        action={{
          href: `/dynamic-flow/v2/${path.split('/public/dynamic-flow/')[1]}`,
          text: 'Take me to the 2.x docs',
        }}
        type="info"
      />
    );
  }
  return <> </>;
};

export default DynamicFlowApp;
