import { NavigationItemOrGroup } from '../../types';

const examplesMenu: NavigationItemOrGroup[] = [
  {
    title: 'Simple forms',
    link: '#collecting-user-input',
    subitems: [
      { title: 'Forms', link: '#forms' },
      { title: 'Selecting values in forms', link: '#selecting-values-in-forms' },
    ],
  },
  {
    title: 'Branching the flow',
    link: '#branching-the-flow',
    subitems: [
      { title: 'Decisions', link: '#decisions' },
      { title: 'Complex selections', link: '#complex-selections' },
    ],
  },
  {
    title: 'Validation',
    link: '#validation',
    subitems: [
      { title: 'Local validation', link: '#local-validation' },
      { title: 'Validation async', link: '#validation-async' },
      { title: 'Validation on submission', link: '#validation-on-submission' },
    ],
  },
  {
    title: 'Refresh on change',
    link: '#refresh-on-change',
  },
  {
    title: 'Flows that leave the app',
    link: '#flows-that-leave-the-app',
    subitems: [
      { title: 'Leaving the flow', link: 'taking-the-user-out-of-the-flow' },
      { title: 'Returning to the flow', link: '#returning-to-the-flow' },
      { title: 'Polling', link: '#waiting-for-external-processes' },
    ],
  },
  {
    title: 'Repeating items',
    link: '#repeating-items',
    subitems: [
      { title: 'Repeatables', link: '#repeatables' },
      {
        title: 'Repeating across multiple steps',
        link: '#gathering-repeating-data-across-multiple-steps',
      },
    ],
  },
  {
    title: 'Using modals',
    link: '#using-modals',
  },
  {
    title: 'Navigation',
    link: '#navigation',
    subitems: [
      { title: 'Back behavior', link: '#repeating-across-multiple-steps' },
      { title: 'Stack behavior', link: '#manipulating-the-navigation-stack-(mobile-only)' },
    ],
  },
  {
    title: 'Persisting values',
    link: '#uploading-files-and-sensitive-information',
  },
  {
    title: 'Maintaining state',
    link: '#maintaining-state',
    subitems: [
      { title: 'Model', link: '#model' },
      { title: 'Hidden schemas', link: '#hidden-schemas' },
    ],
  },
];

const featuresMenu: NavigationItemOrGroup[] = [
  {
    title: 'Overview',
    link: '#dynamic-flow-features',
  },
  {
    title: 'Content display',
    link: '#content-display',
    subitems: [
      { title: 'Alert', link: '#alert' },
      { title: 'Display', link: '#display' },
      { title: 'Heading', link: '#heading' },
      { title: 'Image', link: '#image' },
      { title: 'Instructions', link: '#instructions' },
      { title: 'Loading indicator', link: '#loading-indicator' },
      { title: 'Markdown', link: '#markdown' },
      { title: 'Paragraph', link: '#paragraph' },
      { title: 'Review', link: '#review' },
      { title: 'Status list', link: '#status-list' },
    ],
  },
  {
    title: 'Form components',
    link: '#form-components',
    subitems: [
      { title: 'Checkbox', link: '#checkbox' },
      { title: 'Date', link: '#date' },
      { title: 'Date lookup', link: '#date-lookup' },
      { title: 'Integer', link: '#integer' },
      { title: 'Number', link: '#number' },
      { title: 'Password', link: '#password' },
      { title: 'Phone Number', link: '#phone-number' },
      { title: 'Radio', link: '#radio' },
      { title: 'Repeating items', link: '#repeating-items' },
      { title: 'Select', link: '#select' },
      { title: 'Select (multiple)', link: '#select-(multiple)' },
      { title: 'Text', link: '#text' },
      { title: 'Text area', link: '#text-area' },
      { title: 'Upload', link: '#upload' },
      { title: 'Upload (multiple)', link: '#upload-(multiple)' },
    ],
  },
  {
    title: 'Interactive components',
    link: '#interactive-components',
    subitems: [
      { title: 'Button', link: '#button' },
      { title: 'Decision', link: '#decision' },
      { title: 'Search (async)', link: '#search-(async)' },
    ],
  },
  {
    title: 'Layout components',
    link: '#layout-components',
    subitems: [
      { title: 'Align', link: '#align' },
      { title: 'Box', link: '#box' },
      { title: 'Columns', link: '#columns' },
      { title: 'Margin', link: '#margin' },
      { title: 'Modal', link: '#modal' },
      { title: 'Segmented', link: '#segmented-control' },
      { title: 'Tabs', link: '#tabs' },
    ],
  },
  {
    title: 'Form features',
    link: '#form-features',
    subitems: [
      { title: 'Autocomplete hint', link: '#autocomplete-hint' },
      { title: 'Disabled', link: '#disabled' },
      { title: 'Help', link: '#help' },
      { title: 'Model', link: '#model' },
      { title: 'Persisting data', link: '#persisting-data' },
      { title: 'Placeholder', link: '#placeholder' },
      { title: 'Required', link: '#required' },
      { title: 'Sections', link: '#sections' },
    ],
  },
  {
    title: 'Form features',
    link: '#validation',
    subitems: [
      { title: 'Client-side validation', link: '#client-side-validation' },
      { title: 'Server-side validation', link: '#server-side-validation' },
      { title: 'Validation messages', link: '#validation-messages' },
      { title: 'Validation pattern', link: '#validation-pattern' },
    ],
  },
  {
    title: 'Step features',
    link: '#step-features',
    subitems: [
      { title: 'Back navigation', link: '#back-navigation' },
      { title: 'Camera', link: '#camera' },
      { title: 'External', link: '#external' },
      { title: 'Global error', link: '#global-error' },
      { title: 'Polling', link: '#polling' },
      { title: 'Stack behaviour', link: '#stack-behaviour' },
    ],
  },
  {
    title: 'Success screens',
    link: '#success-screens',
  },
  {
    title: 'Flow events',
    link: '/dynamic-flow/features/events',
  },
];

export { featuresMenu, examplesMenu };
