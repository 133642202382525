import { hasValidConsent, COOKIE_CONSENT_ACCEPT_EVENT_NAME } from '@transferwise/cookie-consent';
import { HttpClient } from '@transferwise/service-comms';
import mixpanel from 'mixpanel-browser';

import { getEnvironmentFromURL } from '../getEnvironmentFromURL';

const MIXPANEL_TOKEN = 'e605c449bdf99389fa3ba674d4f5d919';
const DEV_TOKEN = '1';
const DISTINCT_ID_FALLBACK = Math.random().toString(16).slice(2);

const handleCookieAcceptance = () => {
  mixpanel.opt_in_tracking();
};

// Based on https://github.com/transferwise/crab/blob/master/src/client/services/tracking/mixpanel.ts,
// but as we're generating static pages at build time we cannot rely on the same environment detection.
// Static pages are generated at build time. At that stage we cannot know what the env is (DEPLOYMENT var).
export function initMixpanel() {
  const environment = getEnvironmentFromURL();
  const token = environment === 'production' ? MIXPANEL_TOKEN : DEV_TOKEN;

  const shouldOptOut = !hasValidConsent();
  mixpanel.init(token, {
    opt_out_tracking_by_default: shouldOptOut,
    opt_out_persistence_by_default: shouldOptOut,
    disable_notifications: true,
    batch_flush_interval_ms: 800,
    ...(environment !== 'production' && { debug: true }),
  });

  /**
   * In cases where the user has already consented, i.e., in a non-eu location
   * it is possible that the event listener below will be added *after* the
   * event has already been dispatched.
   *
   * This ensures we opt into tracking at the earliest possible chance, to
   * allow consumers to use `mixpanel.has_opted_in_tracking()`.
   */
  if (hasValidConsent()) {
    mixpanel.opt_in_tracking();
  }

  if (shouldOptOut) {
    window.addEventListener(COOKIE_CONSENT_ACCEPT_EVENT_NAME, handleCookieAcceptance);
  }
}

export const stopListeningToCookieAcceptanceForMixpanel = (): void => {
  window.removeEventListener(COOKIE_CONSENT_ACCEPT_EVENT_NAME, handleCookieAcceptance);
};

export function track(eventName: string, properties = {}) {
  mixpanel.track(`Docs - ${eventName}`, properties);
}

interface TrackCallResult {
  status: string;
}

// Similar to mixpanel track, but proxies the call through Node.js server.
// Benefits: works with adblockers etc, read more:
// https://docs.mixpanel.com/docs/tracking-methods/choosing-the-right-method
export const trackViaServer = async (
  httpClient: HttpClient,
  event: string,
  properties = {},
): Promise<TrackCallResult> => {
  // Based on the following guide: https://dev.to/dellboyan/how-to-setup-mixpanel-analytics-in-nextjs-46
  const commonProperties = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    distinct_id: mixpanel.get_distinct_id() ?? DISTINCT_ID_FALLBACK,
    $device_id: getMixpanelProperty('$device_id'),
    $screen_height: window.screen.height,
    $screen_width: window.screen.width,
    $initial_referrer: getMixpanelProperty('$initial_referrer'),
    $initial_referring_domain: getMixpanelProperty('$initial_referring_domain'),
    $current_url: window.location.href,
  };

  const res = await httpClient.post<TrackCallResult>({
    url: '/docs-api/track',
    upstream: 'docs-api',
    data: { event, properties: { ...commonProperties, ...properties } },
    config: { baseURL: process.env.NEXT_PUBLIC_LOCAL_API_URL || '' },
  });
  return res.data;
};

const getMixpanelProperty = (property: string) => {
  const value = mixpanel.get_property(property) as string;
  return value ?? undefined;
};
