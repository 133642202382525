import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const useIsRetro = () => {
  const router = useRouter();
  const [isRetro, setIsRetro] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (router.isReady) {
      setIsRetro(router.query.retro === 'true');
      setIsReady(true);
    }
  }, [router.isReady, router.query.retro]);

  return { isRetro, isReady };
};

export default useIsRetro;
