import classNames from 'classnames';
import { throttle } from 'lodash';
import Link from 'next/link';
import { JSX, useEffect, useState } from 'react';

import { track } from '../../helpers/tracking';
import { NavigationItemOrGroup } from '../../types';

import styles from './SideBar.module.scss';
import useIsRetro from '../dynamicFlow/markdoc/useIsRetro';

interface Props {
  items: NavigationItemOrGroup[];
}

const SCROLL_DELAY = 200;

const getActiveHeading = () => {
  const headings = document.querySelectorAll('h1, h2');
  const viewportHeight = window.innerHeight;
  let bestMatch;
  for (const heading of Array.from(headings)) {
    const { top } = heading.getBoundingClientRect();
    // element is below mid point of the screen, no need to check further
    if (top > viewportHeight / 2) {
      break;
    }
    // store current match as best, but keep on checking with the next element
    bestMatch = heading.id;
  }
  return bestMatch;
};

export const SideBar = ({ items }: Props): JSX.Element => {
  const [activeHeading, setActiveHeading] = useState<string>();

  useEffect(() => {
    const throttledOnScroll = throttle(() => {
      setActiveHeading(getActiveHeading());
    }, SCROLL_DELAY);
    window.addEventListener('scroll', throttledOnScroll);
    return () => {
      window.removeEventListener('scroll', throttledOnScroll);
    };
  }, []);

  const { isRetro, isReady } = useIsRetro();

  if (!isReady) {
    return <div />;
  }

  return (
    <div className={styles.sidebar}>
      <nav className={styles.sidebarScrollable}>
        <ul className="list-unstyled m-l-1 m-r-3">
          {items.map(({ title, link, active, subitems, showSeparator }) => (
            <li key={title}>
              {link ? (
                <Link
                  href={link}
                  className={classNames(styles.menuItem, styles.link, {
                    [styles.linkActive]: active,
                  })}
                  onClick={() => track('Subnavigation click', { size: 'desktop', link, title })}
                >
                  {title}
                </Link>
              ) : (
                <span className={classNames(styles.menuItem, styles.group)}>{title}</span>
              )}
              {showSeparator && <hr className="m-y-2" />}
              {subitems && (
                <ul className={classNames(styles.subMenu, 'list-unstyled')}>
                  {subitems.map(({ title: subitemTitle, link: subitemLink, hideIfRetro }) => (
                    <li key={subitemTitle} className={isRetro && hideIfRetro ? styles.hide : ''}>
                      {subitemLink ? (
                        <Link
                          href={subitemLink}
                          className={classNames(styles.menuItem, styles.link, {
                            [styles.linkActive]: subitemLink.split('#')[1] === activeHeading,
                          })}
                        >
                          {subitemTitle}
                        </Link>
                      ) : (
                        <span className={classNames(styles.menuItem, styles.group)}>
                          {subitemTitle}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
